import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'arrow_right_door_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="arrow_right_door_outline_56"><path d="M6 28a1.5 1.5 0 011.5-1.5h21.378l-5.939-5.94a1.5 1.5 0 01-.103-2.007l.103-.114a1.5 1.5 0 012.122 0l8.5 8.5a1.5 1.5 0 010 2.122l-8.5 8.5a1.5 1.5 0 01-2.122-2.122l5.939-5.938L7.5 29.5a1.5 1.5 0 01-1.493-1.355L6 28z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M28 7.5A1.5 1.5 0 0129.5 6h5.117c5.019 0 7.117.465 9.244 1.603a10.925 10.925 0 014.536 4.536C49.535 14.266 50 16.364 50 21.383v13.234c0 5.019-.465 7.117-1.603 9.244a10.924 10.924 0 01-4.536 4.536C41.734 49.535 39.636 50 34.617 50H29.5a1.5 1.5 0 010-3h5.117c4.83 0 6.344-.454 7.83-1.248a7.926 7.926 0 003.305-3.306c.794-1.485 1.248-3 1.248-7.83V21.384c0-4.83-.454-6.344-1.248-7.83a7.925 7.925 0 00-3.306-3.305c-1.485-.794-3-1.248-7.83-1.248H29.5A1.5 1.5 0 0128 7.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56ArrowRightDoorOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56ArrowRightDoorOutline: FC<Icon56ArrowRightDoorOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56ArrowRightDoorOutline as any).mountIcon = mountIcon;

export default Icon56ArrowRightDoorOutline;
